<template>

  <div class="row" ref="recordingsForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="en.name"
                      rules="required"
                      name="The Name"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="name"
                                :error="failed ? errors[0]: null"
                                label="Name"
                                name="name"
                                fou
                                v-model="formData.name">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="type"
                      rules="required"
                      name="The Type"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="type"
                        size="large"
                        filterable
                        clearable
                        placeholder="Record Type"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="'Record Type'"
                        :list="builderData.recordingTypes"
                        :listItemLabel="'name'"
                        :listItemValue="'value'"
                        @change="emptyRecord"
                        v-model="formData.type">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="en.media_type"
                      rules="required"
                      name="The Type"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="media_type"
                        size="large"
                        filterable
                        clearable
                        placeholder="Media Type"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="'Media Type'"
                        :list="builderData.mediaTypes"
                        :listItemLabel="'name'"
                        :listItemValue="'value'"
                        @change="emptyRecord"
                        v-model="formData.media_type">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" v-if="formData.media_type == 'UPLOADED'">
                    <div class="form-group">
                      <label v-if="formData.type === 'AUDIO'">Audio</label>
                      <label v-else-if="formData.type === 'VIDEO'">Video</label>
                      <el-tooltip placement="right"  >
                        <div slot="content" v-if="formData.type === 'AUDIO'">
                          (Audio only)
                        </div>
                        <div slot="content" v-if="formData.type === 'VIDEO'">
                          (Video only)
                        </div>
                        <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                      </el-tooltip>
                      <single-file-uploader-file-manager
                        v-if="formData.type === 'AUDIO'"
                        :preview-width="'200px'"
                        :preview-height="'200px'"
                        :fileType="'audio'"
                        v-model="formData.record"
                        :accepted-files="audioExtensions">
                      </single-file-uploader-file-manager>
                      <single-file-uploader-file-manager
                        v-if="formData.type === 'VIDEO'"
                        :preview-width="'200px'"
                        :preview-height="'200px'"
                        :fileType="'audio'"
                        v-model="formData.record"
                        :accepted-files="videoExtensions">
                      </single-file-uploader-file-manager>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="formData.media_type == 'LINK'">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="en.link"
                      rules="required"
                      name="The Link"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="link"
                                :error="failed ? errors[0]: null"
                                label="Link"
                                name="link"
                                fou
                                v-model="formData.record">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/recordings/list/'+formData.session_id)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import {Option, Select, Tooltip} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import PrimeAudioUploader from "../../../components/PrimeAudioUploader";
import SingleFileUploaderFileManager from "../../../components/FileManager/SingleFileUploaderFileManager";




extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    SingleFileUploaderFileManager,
    PrimeAudioUploader,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      audioExtensions: this.getAudioExtensions(),
      videoExtensions: this.getVideoExtensions(),
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        name: "",
        type: "",
        record: "",
        session_id: '',
        media_type: '',
        link: '',
      },
      builderData: {
        recordingTypes: [],
        mediaTypes: [],
      },

    };
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.recordingsForm
    });

    let data = {}
    this.axios.post("recordings/builder", data).then((response) => {
      this.builderData.recordingTypes = response.data.recordingTypes;
      this.builderData.mediaTypes = response.data.mediaTypes;

      this.id = this.$route.params['id'];
      this.formData.session_id = this.$route.params['session_id'];


      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Recording";
        this.getRecording();
      } else {
        this.editMode = false;
        this.formTitle = "Add Recording";
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    })
  },
  methods: {
    getRecording() {
      let data = {
        'id': this.id,
      };
      this.axios.post("recordings/get", data).then((response) => {
        this.formData = response.data;
        if(this.formData.media_type == null){
          this.formData.media_type = 'LINK';
        }
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Recording Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      let formData = this.formData;
      if (this.editMode === true) {
        request = this.axios.put("recordings/update/" + this.id, formData);
        successMessage = "Recording Updated Successfully";
      } else {
        request = this.axios.post("recordings/create", formData);
        successMessage = "Recording Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/recordings/list/"+this.formData.session_id);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    generateSomeFields() {
      let newUrl = '';
      if (this.formData.title) {
        newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "_");
      }
      this.formData.url = newUrl;
    },
    emptyRecord(){
      this.formData.record = null;
      this.formData.link = null;
    },
  }
}
</script>
